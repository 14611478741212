import React, { FC } from 'react';

import { Block } from '@components/layout/block';
import { Center } from '@components/layout/center';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { useDetectMedia } from '@hooks/useDetectMedia';

export const ResetURLSent: FC = () => {
  const { md } = useDetectMedia();

  return (
    <Center>
      <VStack spacing="30px" textAlign="center" px={md ? '0' : '40px'}>
        <Heading2 fontSize="24px" lh="40px">
          パスワードの再設定URLを送信しました
        </Heading2>
        <Block textAlign="center">
          <Paragraph lh="17px" align="center">
            パスワードの再設定URLを送信しました。
          </Paragraph>
          <Flex direction={md ? 'row' : 'column'}>
            <Paragraph lh="17px" width="fit-content">
              メールに記載のURLをクリックし、
            </Paragraph>
            <Paragraph lh="17px" width="fit-content">
              パスワードの変更画面に進んでください。
            </Paragraph>
          </Flex>
        </Block>
      </VStack>
    </Center>
  );
};
